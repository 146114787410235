import GATSBY_COMPILED_MDX from "/Users/imac/Russellwzr.github.io/content/blog/20230616_glossary_w/index.mdx";
import * as React from 'react';
import {graphql} from 'gatsby';
import {Layout} from '../components/layout';
import {Seo} from '../components/common';
const BlogPostTemplate = ({data: {mdx: post}, children}) => {
  return React.createElement(Layout, null, React.createElement("article", {
    className: "prose max-w-none dark:prose-invert",
    itemScope: true,
    itemType: "http://schema.org/Article",
    style: {
      marginTop: '3rem'
    }
  }, React.createElement("header", null, React.createElement("h1", {
    itemProp: "headline"
  }, post.frontmatter.title), React.createElement("p", null, post.frontmatter.date)), children));
};
export const Head = ({data: {mdx: post}}) => {
  return React.createElement(Seo, {
    title: post.frontmatter.title,
    description: post.frontmatter.description || post.excerpt
  });
};
export const pageQuery = graphql`
  query BlogPostTemplate($id: String!) {
    mdx(id: { eq: $id }) {
      frontmatter {
        date(formatString: "MMMM DD, YYYY")
        description
        tag
        title
      }
      fields {
        timeToRead {
          minutes
          time
          words
        }
      }
    }
  }
`;
BlogPostTemplate
export default function GatsbyMDXWrapper(props) {
  return React.createElement(BlogPostTemplate, props, React.createElement(GATSBY_COMPILED_MDX, props));
}
